import { DataGrid, SaveSuccessSnackbar } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import { usePostApi } from '@/hooks/use-post-api';
import { User } from '@/types/user';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import { Check, Close, PersonAdd, Settings } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, lighten, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { EditUserDialog } from './edit-existing-user-dialog';
import { EditProvisionedUserDialog } from './edit-provisioned-user-dialog';
import { ProvisionNewUserDialog } from './provision-new-user-dialog';

/**
 * owns the shared user table and controlling the dialogs for editing and provisioning users
 */
export const OrchestrationsManageUsers = () => {
  const theme = useTheme();

  // #region state
  const [users, setUsers] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [hoveredRow, setHoveredRow] = useState('');

  const [saveSuccess, setSaveSuccess] = useState('');

  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [provisionUserDialogOpen, setProvisionUserDialogOpen] = useState(false);
  const [editProvisionedUserDialogOpen, setEditProvisionedUserDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // #endregion state

  // #region API management
  const [apiUsers] = useGetApi<User[]>(`tatsu/orchestrations/userList`);
  const { postData: postUpdateUser } = usePostApi<object>('tatsu/orchestrations/actions/updateUser');
  const { postData: postProvisionUser } = usePostApi<object>('tatsu/orchestrations/actions/provisionUser');
  const { postData: postUpdateProvisionedUser } = usePostApi<object>(
    'tatsu/orchestrations/actions/updateProvisionedUser'
  );

  useEffect(() => {
    setUsers(apiUsers ?? []);
  }, [apiUsers]);

  // Function to save changes (you'll need to implement the logic for saving)
  const handleSaveUserUpdate = async (updatedUser: User) => {
    await postUpdateUser({ ...updatedUser, ...updatedUser.toggles });
    setUsers((currentUsers) => currentUsers.map((user) => (user.uuid === updatedUser.uuid ? updatedUser : user)));
    setSaveSuccess('User Updated successfully!');
    setEditUserDialogOpen(false);
  };

  const handleSaveProvisionUser = async (provisionedUser: User) => {
    setDisabled(true);
    await postProvisionUser({ ...provisionedUser, ...provisionedUser.toggles });
    setUsers((currentUsers) => [...currentUsers, provisionedUser as User]);
    setSaveSuccess('New User Provisioned Successfully!');
    setProvisionUserDialogOpen(false);
    setDisabled(false);
  };

  const handleSaveUpdateProvisionedUser = async (provisionedUser: User) => {
    await postUpdateProvisionedUser({ ...provisionedUser, ...provisionedUser.toggles });
    setUsers((currentUsers) =>
      currentUsers.map((user) =>
        user.email.toLowerCase() === provisionedUser.email.toLowerCase() ? (provisionedUser as User) : user
      )
    );
    setSaveSuccess('Provisioned User Updated Successfully!');
    setEditProvisionedUserDialogOpen(false);
  };
  // #region API management

  // #region data grid management
  // Function to handle dialog open
  const handleEditUserClick = (user: User) => {
    setCurrentUser(user);
    if (user.uuid) {
      setEditUserDialogOpen(true);
    } else {
      setEditProvisionedUserDialogOpen(true);
    }
  };

  const columns = [
    {
      field: 'actions',
      title: ' ',
      width: 15,
      minWidth: 0,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (hoveredRow === params.id.toString()) {
          return (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setCurrentUser(params.row);
                handleEditUserClick(params.row);
              }}
              sx={{
                color: theme.palette.primary.contrastText
              }}
            >
              <Settings fontSize="small" style={{ color: theme.palette.green.light }} />
            </IconButton>
          );
        } else return null;
      }
    },
    {
      field: 'email',
      title: 'Email',
      resizable: true,
      width: 150
    },
    {
      field: 'uuid',
      title: 'UUID',
      resizable: true,
      width: 150,
      valueGetter: (params: { row: { uuid?: string } }) =>
        params.row.uuid ? params.row.uuid : 'pending email verification and login'
    },
    {
      field: 'firstName',
      title: 'First Name',
      resizable: true,
      width: 100,
      valueGetter: (params: { row: { firstName?: string } }) =>
        params.row.firstName ? params.row.firstName : 'pending email verification and login'
    },
    {
      field: 'lastName',
      title: 'Last Name',
      resizable: true,
      width: 100,
      valueGetter: (params: { row: { lastName?: string } }) =>
        params.row.lastName ? params.row.lastName : 'pending email verification and login'
    },
    {
      field: 'canChatWithOpenAI',
      title: 'Chat with OpenAI',
      resizable: true,
      width: 125,
      type: 'boolean',
      valueGetter: (params: { row: { toggles: { canChatWithOpenAI?: boolean } } }) =>
        !!params.row.toggles?.canChatWithOpenAI,
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? (
          <Check style={{ color: lighten(theme.palette.green.light, 0.3) }} />
        ) : (
          <Close style={{ color: lighten(theme.palette.red.light, 0.3) }} />
        );
      }
    },
    {
      field: 'canFillMetaschema',
      title: 'Can Fill Metaschema',
      resizable: true,
      width: 125,
      type: 'boolean',
      valueGetter: (params: { row: { toggles: { canFillMetaschema?: boolean } } }) =>
        !!params.row.toggles?.canFillMetaschema,
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? (
          <Check style={{ color: lighten(theme.palette.green.light, 0.3) }} />
        ) : (
          <Close style={{ color: lighten(theme.palette.red.light, 0.3) }} />
        );
      }
    },
    {
      field: 'canAccessKnowledgeFinder',
      title: 'Access KF',
      resizable: true,
      width: 125,
      type: 'boolean',
      valueGetter: (params: { row: { toggles: { canAccessKnowledgeFinder?: boolean } } }) =>
        !!params.row.toggles?.canAccessKnowledgeFinder,
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? (
          <Check style={{ color: lighten(theme.palette.green.light, 0.3) }} />
        ) : (
          <Close style={{ color: lighten(theme.palette.red.light, 0.3) }} />
        );
      }
    },
    {
      field: 'canUseLocalModels',
      title: 'Chat with Local Models',
      resizable: true,
      width: 125,
      type: 'boolean',
      valueGetter: (params: { row: { toggles: { canUseLocalModels?: boolean } } }) =>
        !!params.row.toggles?.canUseLocalModels,
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? (
          <Check style={{ color: lighten(theme.palette.green.light, 0.3) }} />
        ) : (
          <Close style={{ color: lighten(theme.palette.red.light, 0.3) }} />
        );
      }
    },
    {
      field: 'preferredLanding',
      title: 'Preferred Landing',
      resizable: true,
      width: 125,
      valueGetter: (params: { row: { toggles: { preferredLanding?: string } } }) => params.row.toggles?.preferredLanding
    }
  ];
  // #endregion data grid management

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(5),
        left: 0,
        right: 0,
        pl: theme.spacing(5),
        pr: theme.spacing(5)
      }}
    >
      <Typography variant="h4" sx={{ color: 'black', fontWeight: '400' }}>
        Manage Users
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], mb: theme.spacing(5) }}>
        manage existing, or provision new, users
      </Typography>
      <Box sx={{ pb: theme.spacing(3) }}>
        <Button
          disabled={disabled}
          variant="clean-creative"
          startIcon={<PersonAdd />}
          onClick={() => setProvisionUserDialogOpen(true)}
        >
          Provision User
        </Button>
      </Box>
      <Box>
        <DataGrid
          rows={users ?? []}
          columns={columns}
          checkboxSelection={false}
          autoHeight={false}
          style={{ height: '50vh', width: '100%' }}
          disableVirtualization={true}
          slotProps={{
            row: {
              onMouseEnter: (e) => {
                setHoveredRow(e.currentTarget.getAttribute('data-id') ?? '');
              },
              onMouseLeave: () => {
                setHoveredRow('');
              }
            }
          }}
          sx={{ ...getHideScrollBarProps() }}
        />
      </Box>
      <SaveSuccessSnackbar setSaveSuccess={setSaveSuccess} saveSuccess={saveSuccess} />
      <EditUserDialog
        open={editUserDialogOpen}
        onClose={() => setEditUserDialogOpen(false)}
        user={currentUser}
        onSave={handleSaveUserUpdate}
        theme={theme}
      />
      <ProvisionNewUserDialog
        disabled={disabled}
        open={provisionUserDialogOpen}
        onClose={() => setProvisionUserDialogOpen(false)}
        onSave={handleSaveProvisionUser}
        theme={theme}
      />
      <EditProvisionedUserDialog
        open={editProvisionedUserDialogOpen}
        onClose={() => setEditProvisionedUserDialogOpen(false)}
        user={currentUser}
        onSave={handleSaveUpdateProvisionedUser}
        theme={theme}
      />
    </Box>
  );
};
