import { EditPage, SaveSuccessSnackbar } from '@/components';
import { useFeatureToggle } from '@/features/feature-toggle';
import { useGetApi } from '@/hooks/use-get-api';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import { SNACKBAR_HANG_TIME } from '@/types/constants';
import { LibraryProject } from '@/types/projects';
import { UserPermission } from '@/types/user';
import { getBrandedColorFromString } from '@/utils/color-utils';
import { formatDateString } from '@/utils/date-utils';
import { getNameOrEmail } from '@/utils/name-utils';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import { HelpOutline } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultModel } from '../../../../models/config';
import { useCollectionContext } from '../../stores/collection-context';
import { useDocumentContext } from '../../stores/document-context';
import { DocumentCollection, DocumentTag, FileMetadata } from '../../types';
import { ModelSelection } from '../model-selection';
import { ReusableSettingsTextField } from './reusable-settings-text-field';

export const DocumentSettings = () => {
  const theme = useTheme();

  // #region state
  const navigate = useNavigate();
  const { flags } = useFeatureToggle();
  const { collection, collectionPermission: accessLevelFromContext } = useCollectionContext();
  const { documents, setDocuments: setCurrentDocuments, currentDocument, setCurrentDocument } = useDocumentContext();
  const { collectionId, documentId, projectId } = useParams<{
    collectionId: string;
    documentId: string;
    projectId: string;
  }>();
  const [accessLevel, setAccessLevel] = useState(accessLevelFromContext);
  const [state, setState] = useState<FileMetadata>();
  const [saveSuccess, setSaveSuccess] = useState('');
  const [isAutofillingMetadata, setIsAutofillingMetadata] = useState(false);
  const [autofillSuccess, setAutofillSuccess] = useState('');

  const [selectedModel, setSelectedModel] = useState<string>(defaultModel);
  const handleModelChange = (model: string) => {
    setSelectedModel(model);
  };
  // #endregion state

  // #region API handlers
  const { getData: getDocumentsForCollection } = useGetApiViaCallback<FileMetadata[]>(
    `knowledge-finder/document/${projectId}/${collectionId}/documents`
  );
  const { getData: getDocumentCollectionInfo } = useGetApiViaCallback<{
    access: UserPermission;
    documentCollection: DocumentCollection;
    library: LibraryProject;
  }>(`knowledge-finder/collection/${projectId}/${collectionId}`);
  const [tags] = useGetApi<DocumentTag[]>(`knowledge-finder/document/documentTags/${projectId}`);
  const { postData: postEditDocument } = usePostApi<FileMetadata>('knowledge-finder/document/edit');
  const { postData: postRemoveDocument } = usePostApi<void>('knowledge-finder/delete/document');
  const { postData: postAutofillMetadata } = usePostApi<FileMetadata>('knowledge-finder/document/autofill');
  // #endregion API handlers

  /**
   * support refresh flow and fetching the information necessary to render the page on init
   */
  useEffect(() => {
    if (documents.length) {
      const foundDocument = documents?.find((doc: FileMetadata) => doc.uuid === documentId);
      if (foundDocument && !state) {
        // only set the state if it's not already set
        setCurrentDocument(foundDocument);
        setState(foundDocument);
      }
    } else {
      const getAllDocumentsForCollection = async () => {
        try {
          const [documentResults, docColInfo] = await Promise.all([
            getDocumentsForCollection(),
            getDocumentCollectionInfo()
          ]);
          setCurrentDocuments(documentResults);
          setAccessLevel(docColInfo.access);
        } catch (err) {
          console.error(err);
        }
      };
      getAllDocumentsForCollection();
    }
  }, [
    documentId,
    documents,
    getDocumentsForCollection,
    getDocumentCollectionInfo,
    setCurrentDocuments,
    setAccessLevel,
    setCurrentDocument,
    state
  ]);

  if (!state || !currentDocument) {
    return <></>;
  }

  // #region event handlers
  // handle date picker change
  const handleDateChange = (newDate: Date | null) => {
    setState((prevState) => {
      if (!prevState) return undefined; // Handle the case when prevState is undefined
      return { ...prevState, displayDate: newDate ?? '', uuid: prevState.uuid || 'default-uuid' };
    });
  };

  // handle text field changes
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target as HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown };
    if (name) {
      setState((prevState) => {
        if (!prevState) return undefined; // Handle the case when prevState is undefined
        // Ensure that `name` is a key of `FileMetadata`
        if (name in prevState) {
          return { ...prevState, [name]: value };
        }
        return prevState; // Return the previous state if the name is not a valid key
      });
    }
  };
  // #endregion event handlers

  const tooltipProps = {
    tooltip: {
      sx: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[700]
      }
    }
  };
  const dateSx = {
    mb: theme.spacing(1),
    mt: theme.spacing(1),
    '& .MuiInputBase-inputMultiline': {
      color: theme.palette.text.primary,
      resize: 'vertical'
    },
    backgroundColor: 'transparent !important', // Ensure background is transparent
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent !important', // Ensure background is transparent
      '&:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white
      },
      '&:hover:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white on hover with a thicker line
      },
      '&.Mui-focused:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white when focused with a thicker line
      },
      '&.Mui-focused:after': {
        borderBottom: `1px solid ${theme.palette.text.primary}` // Changes the focused state underline to white
      }
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.secondary.light, // Set the label text color to white
      transform: 'translate(0, -6px) scale(0.75)', // Adjust the label position to float above and scale it down
      ...theme.typography.h6 // Apply h6 style from the theme
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.primary, // Placeholder text color
      opacity: 1 // Override reduced opacity for placeholders if needed
    }
  };

  const handleAutofillOneMetadata = async (metaLabel: string) => {
    setIsAutofillingMetadata(true);
    try {
      const autofilledDocument = await postAutofillMetadata({
        documentId: currentDocument.uuid,
        documentCollectionId: collectionId ?? currentDocument.collections?.[0].uuid,
        projectId,
        model: selectedModel,
        targetMetadataLabel: metaLabel
      });
      setState(autofilledDocument);
      setCurrentDocuments((prev: FileMetadata[]) =>
        prev.map((doc) => {
          if (doc.uuid === autofilledDocument.uuid) {
            return {
              ...doc,
              displayDate: autofilledDocument.displayDate,
              displayName: autofilledDocument.displayName,
              content: autofilledDocument.content,
              tags: autofilledDocument.tags,
              contact: autofilledDocument.contact,
              flexibleMetaschema: autofilledDocument.flexibleMetaschema
            };
          }
          return doc;
        })
      );
      if (autofilledDocument?.flexibleMetaschema?.[metaLabel]) {
        setCurrentDocument({
          ...autofilledDocument,
          flexibleMetaschema: autofilledDocument.flexibleMetaschema,
          autofillCompleted: !!autofilledDocument?.flexibleMetaschema?.[metaLabel]
        });
        setAutofillSuccess(`Autofilled the metadata for: ${metaLabel}`);
      } else {
        setAutofillSuccess(`Unable to autofill the metadata based on the document contents for: ${metaLabel}`);
      }
    } finally {
      setIsAutofillingMetadata(false);
    }
  };

  return (
    <EditPage
      header={'Document Metadata'}
      shouldPinBottom={!!collection?.flexibleMetaschema && flags?.canFillMetaschema}
      navigate={navigate}
      handleClose={() => setCurrentDocument(undefined)}
      confirmDeleteMsg="Are you sure you want to delete this file? This action cannot be undone."
      postDeleteEntity={async () => {
        // delete the doc
        await postRemoveDocument({
          documentId: currentDocument.uuid,
          documentCollectionId: collectionId ?? currentDocument.collections?.[0].uuid, // fall back to its origin collection
          projectId
        });
        // update current docs
        setCurrentDocuments((prev: FileMetadata[]) => prev.filter((doc) => doc.uuid !== currentDocument.uuid));
        navigate(`/v2/knowledge-finder/${projectId}/collections/${collectionId}`);
      }}
      onSaveClicked={async () => {
        const updatedDocument = await postEditDocument({
          documentId: currentDocument.uuid,
          documentCollectionId: collectionId ?? currentDocument.collections?.[0].uuid, // fall back to its origin collection
          projectId,
          displayName: state.displayName,
          displayDate: state.displayDate,
          summary: state.content,
          tags: state.tags,
          contact: state.contact,
          ...(state.flexibleMetaschema && { flexibleMetaschema: JSON.stringify(state.flexibleMetaschema) })
        });
        setSaveSuccess('Document information updated successfully!');
        if (updatedDocument) {
          setCurrentDocuments((prev: FileMetadata[]) =>
            prev.map((doc) => {
              if (doc.uuid === updatedDocument.uuid) {
                return {
                  ...doc,
                  displayDate: updatedDocument.displayDate,
                  displayName: updatedDocument.displayName,
                  content: updatedDocument.content,
                  tags: updatedDocument.tags,
                  contact: updatedDocument.contact,
                  flexibleMetaschema: updatedDocument.flexibleMetaschema
                };
              }
              return doc;
            })
          );
        }
        // update tags with created tags with their permanent UUIDs
        setState((prevState) => {
          if (!prevState) return prevState; // or provide a default FileMetadata object with a valid uuid string
          return { ...prevState, tags: updatedDocument.tags, uuid: prevState.uuid || 'default-uuid' };
        });
        navigate(`/v2/knowledge-finder/${projectId}/collections/${collectionId}`);
      }}
    >
      <Box
        sx={{
          overflow: 'auto',
          ...getHideScrollBarProps(),
          ...(!!collection?.flexibleMetaschema && flags?.canFillMetaschema && { display: 'flex', flexDirection: 'row' })
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
            ...getHideScrollBarProps(),
            ...(!!collection?.flexibleMetaschema && flags?.canFillMetaschema && { flex: 1, pr: theme.spacing(2) })
          }}
        >
          {!!collection?.flexibleMetaschema && flags?.canFillMetaschema && (
            <Typography variant="subtitle1" sx={{ fontSize: 15, color: theme.palette.green.light }}>
              Base Metadata
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ color: theme.palette.secondary.light }}>
            Filename: {state.title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.secondary.light, mt: theme.spacing(-1), pb: theme.spacing(3) }}
          >
            Uploaded: {formatDateString(state.createdAt)} by{' '}
            {getNameOrEmail({
              email: state.uploadedBy.email,
              firstName: state.uploadedBy.firstName,
              lastName: state.uploadedBy.lastName
            })}
          </Typography>
          <ReusableSettingsTextField
            label="Name"
            name="displayName"
            value={state.displayName || undefined}
            onChange={handleInputChange}
            theme={theme}
            placeholder={state.title}
          />
          <ReusableSettingsTextField
            label="Summary"
            name="content"
            value={state.content || undefined}
            onChange={handleInputChange}
            theme={theme}
            placeholder="Enter summary here..."
            maxLength={5000}
          />
          <ReusableSettingsTextField
            label="Contact"
            name="contact"
            value={state.contact || undefined}
            onChange={handleInputChange}
            theme={theme}
            placeholder={getNameOrEmail({
              email: state.uploadedBy.email,
              firstName: state.uploadedBy.firstName,
              lastName: state.uploadedBy.lastName
            })}
          />
          <DatePicker
            label="DATE"
            value={
              state.displayDate
                ? typeof state.displayDate === 'string'
                  ? parseISO(state.displayDate)
                  : state.displayDate
                : null
            }
            onChange={handleDateChange}
            slotProps={{
              inputAdornment: {
                position: 'start'
              },
              actionBar: {
                actions: ['clear']
              },
              openPickerIcon: { fontSize: 'large' },
              openPickerButton: { color: 'secondary' },
              textField: {
                variant: 'filled',
                focused: true,
                color: 'secondary'
              }
            }}
            sx={dateSx}
          />
          <Autocomplete
            multiple
            disabled={accessLevel !== UserPermission.OWNER}
            id="tags-filled"
            options={(tags ?? []).filter((tag) => !state.tags.some((selectedTag) => selectedTag.name === tag.name))}
            freeSolo
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            value={state.tags ?? []}
            sx={{
              '.MuiAutocomplete-tag': {
                background: 'transparent',
                display: 'flex',
                flexWrap: 'wrap',
                maxHeight: '96px',
                overflow: 'hidden'
              },
              '& .MuiFilledInput-root': {
                '&:before': {
                  borderBottom: '`1px solid ${theme.palette.text.primary}`' // Set the underline to white
                },
                '&:hover:before': {
                  borderBottom: '`1px solid ${theme.palette.text.primary}`' // Set the underline to white on hover with a thicker line
                },
                '&.Mui-focused:before': {
                  borderBottom: '`1px solid ${theme.palette.text.primary}`' // Set the underline to white when focused with a thicker line
                },
                '&.Mui-focused:after': {
                  borderBottom: 'none' // Attempt to disable the green underline when focused
                },
                borderBottom: '`1px solid ${theme.palette.text.primary}`',
                backgroundColor: 'transparent'
              },
              // Increase specificity here for the focused after state
              '& .MuiInput-underline:after': {
                borderBottom: 'none !important' // Force removal of the underline
              },
              '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"].Mui-focused:after': {
                borderBottom: 'none !important' // Specifically target the focused state of the input within Autocomplete
              }
            }}
            onChange={(_event, newValue) => {
              setState((prevState) => {
                if (!prevState) return undefined; // If prevState is undefined, return undefined
                // Handle the creation of new tags and maintaining existing ones
                const updatedTags = compact(
                  newValue.map((tag) => {
                    // If the tag is a string, it's a new tag entered via freeSolo so it'll leverage the tag's name as the UUID temporarily
                    if (typeof tag === 'string') {
                      const tagExistsInDB = tags?.some((t) => t.name === tag);
                      const tagExistsInState = state.tags?.some((t) => t.name === tag);
                      if (tagExistsInDB) {
                        // real record already create for the tag
                        return tags?.find((t) => t.name === tag);
                      }
                      if (tagExistsInState) {
                        // temp record already created the tag
                        return;
                      }
                      return {
                        name: tag,
                        uuid: tag // temporary uuid, replaced upon saving
                      };
                    }
                    return tag;
                  })
                );
                return { ...prevState, tags: updatedTags };
              });
            }}
            renderTags={(value, getTagProps) =>
              value.map((tag, index) => (
                <Chip
                  variant="filled"
                  size="small"
                  style={{
                    backgroundColor: theme.palette[getBrandedColorFromString(tag.name)].main,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontSize: '10px',
                    marginBottom: theme.spacing(3)
                  }}
                  label={tag.name}
                  {...getTagProps({ index })}
                  key={tag.uuid} // Use the tag's uuid as the key
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                sx={{
                  mb: theme.spacing(1),
                  mt: theme.spacing(1),
                  borderBottom: `1px solid ${theme.palette.text.primary}`,
                  '& .MuiFilledInput-root': {
                    '&:before': {
                      borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white
                    },
                    '&:hover:before': {
                      borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white on hover with a thicker line
                    },
                    '&.Mui-focused:before': {
                      borderBottom: `1px solid ${theme.palette.text.primary}` // Set the underline to white when focused with a thicker line
                    },
                    '&.Mui-focused:after': {
                      borderBottom: `1px solid ${theme.palette.text.primary}` // Changes the focused state underline to white
                    },
                    borderBottom: `1px solid ${theme.palette.text.primary}`,
                    backgroundColor: 'transparent'
                  }
                }}
                multiline
                rows={2}
                {...params}
                variant="standard"
                label={
                  <>
                    Tags
                    {accessLevel === UserPermission.OWNER && (
                      <Tooltip
                        placement="right"
                        title={
                          <Typography variant="body2">
                            Type to create or select tags. Press enter to apply and save them.
                          </Typography>
                        }
                        componentsProps={tooltipProps}
                      >
                        <IconButton
                          size="small"
                          sx={{ verticalAlign: 'middle', ml: theme.spacing(0.5), pb: theme.spacing(1) }}
                        >
                          <HelpOutline fontSize="inherit" style={{ fontSize: '1.1rem' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: theme.palette.secondary.light, // Set the label text color to white
                    transform: 'translate(0, -6px) scale(0.75)', // Adjust the label position to float above and scale it down
                    ...theme.typography.h6 // Apply h6 style from the theme
                  }
                }}
                placeholder={accessLevel !== UserPermission.OWNER ? '' : ' Add tags'}
              />
            )}
          />
        </Box>
        {!!collection?.flexibleMetaschema && flags?.canFillMetaschema && (
          <Divider
            orientation="vertical" // Change orientation to 'vertical'
            flexItem
            sx={{
              bgcolor: theme.palette.green.light,
              mx: theme.spacing(2) // Add margin for spacing between columns
            }}
          />
        )}
        <Box
          sx={{
            overflow: 'auto',
            ...getHideScrollBarProps(),
            ...(!!collection?.flexibleMetaschema && flags?.canFillMetaschema && { flex: 1, pl: theme.spacing(2) })
          }}
        >
          {!!collection?.flexibleMetaschema && flags?.canFillMetaschema && (
            <Box sx={{ position: 'relative' }}>
              <Typography variant="subtitle1" sx={{ fontSize: 15, color: theme.palette.green.light }}>
                Collection Metadata
              </Typography>
              <Box sx={{ pt: theme.spacing(2), pb: theme.spacing(2) }}>
                <Box>
                  <Box
                    component="button"
                    disabled={isAutofillingMetadata}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                      padding: 0,
                      '&:hover': {
                        textDecoration: 'none'
                      }
                    }}
                    onClick={async () => {
                      setIsAutofillingMetadata(true);
                      try {
                        const autofilledDocument = await postAutofillMetadata({
                          documentId: currentDocument.uuid,
                          documentCollectionId: collectionId ?? currentDocument.collections?.[0].uuid,
                          projectId,
                          model: selectedModel
                        });
                        setState(autofilledDocument);
                        setCurrentDocuments((prev: FileMetadata[]) =>
                          prev.map((doc) => {
                            if (doc.uuid === autofilledDocument.uuid) {
                              return {
                                ...doc,
                                displayDate: autofilledDocument.displayDate,
                                displayName: autofilledDocument.displayName,
                                content: autofilledDocument.content,
                                tags: autofilledDocument.tags,
                                contact: autofilledDocument.contact,
                                flexibleMetaschema: autofilledDocument.flexibleMetaschema
                              };
                            }
                            return doc;
                          })
                        );
                        setCurrentDocument({
                          ...autofilledDocument,
                          flexibleMetaschema: autofilledDocument.flexibleMetaschema,
                          autofillCompleted: true
                        });
                        setAutofillSuccess(
                          `Autofilled the following metadata: ${Object.keys(autofilledDocument?.flexibleMetaschema || {}).join(', ')}`
                        );
                      } finally {
                        setIsAutofillingMetadata(false);
                      }
                    }}
                  >
                    {isAutofillingMetadata && (
                      <CircularProgress size={35} sx={{ color: theme.palette.green.lightest }} />
                    )}
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        bgcolor: theme.palette.green.lightest,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&:hover': {
                          textDecoration: 'none'
                        },
                        ...(isAutofillingMetadata && { ml: theme.spacing(3) })
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.green.main, fontSize: '1rem', position: 'relative' }}
                      >
                        ✦
                      </Typography>
                    </Box>
                    <Typography
                      variant="button"
                      sx={{
                        pl: theme.spacing(1),
                        color: theme.palette.text.primary,
                        fontWeight: 600
                      }}
                    >
                      Autofill All
                    </Typography>
                  </Box>
                  <ModelSelection
                    boxStyle={{ pt: theme.spacing(2), pb: theme.spacing(2) }}
                    selectedModel={selectedModel}
                    onModelSelect={handleModelChange}
                  />
                </Box>
              </Box>
              {collection?.flexibleMetaschema.map((meta) => {
                if (meta.type === 'date') {
                  const flexibleDate = state.flexibleMetaschema?.[meta.label];
                  return (
                    <Box key={meta.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component="button"
                        disabled={isAutofillingMetadata}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                          padding: 0,
                          '&:hover': {
                            textDecoration: 'none'
                          }
                        }}
                        onClick={() => handleAutofillOneMetadata(meta.label)}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            bgcolor: theme.palette.green.lightest,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              textDecoration: 'none'
                            }
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.green.main, fontSize: '1rem', position: 'relative' }}
                          >
                            ✦
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ pl: theme.spacing(2), flex: 1 }}>
                        <DatePicker
                          key={meta.id}
                          label={
                            <Tooltip
                              title={<Typography variant="body2">{`Prompt: ${meta.prompt}`}</Typography>}
                              componentsProps={tooltipProps}
                            >
                              <Typography fontSize={21}>{meta.label}</Typography>
                            </Tooltip>
                          }
                          value={
                            flexibleDate
                              ? typeof flexibleDate === 'string'
                                ? parseISO(flexibleDate)
                                : flexibleDate instanceof Date
                                  ? flexibleDate
                                  : null
                              : null
                          }
                          onChange={(newDate) => {
                            setState((prevState) => {
                              if (!prevState) return undefined;
                              const updatedFlexibleMetaschema = {
                                ...prevState.flexibleMetaschema,
                                [meta.label]: newDate ? newDate.toISOString() : null
                              };
                              return { ...prevState, flexibleMetaschema: updatedFlexibleMetaschema };
                            });
                          }}
                          slotProps={{
                            inputAdornment: {
                              position: 'start'
                            },
                            actionBar: {
                              actions: ['clear']
                            },
                            openPickerIcon: { fontSize: 'large' },
                            openPickerButton: { color: 'secondary' },
                            textField: {
                              variant: 'filled',
                              focused: true,
                              color: 'secondary'
                            }
                          }}
                          sx={dateSx}
                        />
                      </Box>
                    </Box>
                  );
                } else if (meta.type === 'text') {
                  return (
                    <Box key={meta.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component="button"
                        disabled={isAutofillingMetadata}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                          padding: 0,
                          '&:hover': {
                            textDecoration: 'none'
                          }
                        }}
                        onClick={() => handleAutofillOneMetadata(meta.label)}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            bgcolor: theme.palette.green.lightest,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              textDecoration: 'none'
                            }
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.green.main, fontSize: '1rem', position: 'relative' }}
                          >
                            ✦
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ pl: theme.spacing(2), flex: 1 }}>
                        <ReusableSettingsTextField
                          key={meta.id}
                          label={
                            <Tooltip
                              title={<Typography variant="body2">{`Prompt: ${meta.prompt}`}</Typography>}
                              componentsProps={tooltipProps}
                            >
                              <Typography fontSize={21}>{meta.label}</Typography>
                            </Tooltip>
                          }
                          name="content"
                          value={String(state.flexibleMetaschema?.[meta.label] ?? '')}
                          onChange={(event) => {
                            const { value } = event.target;
                            setState((prevState) => {
                              if (!prevState) return undefined;
                              const updatedFlexibleMetaschema = {
                                ...prevState.flexibleMetaschema,
                                [meta.label]: value
                              };
                              return { ...prevState, flexibleMetaschema: updatedFlexibleMetaschema };
                            });
                          }}
                          theme={theme}
                          placeholder={`Enter text here...`}
                          maxLength={5000}
                        />
                      </Box>
                    </Box>
                  );
                } else if (meta.type === 'number') {
                  return (
                    <Box key={meta.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component="button"
                        disabled={isAutofillingMetadata}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                          padding: 0,
                          '&:hover': {
                            textDecoration: 'none'
                          }
                        }}
                        onClick={() => handleAutofillOneMetadata(meta.label)}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            bgcolor: theme.palette.green.lightest,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              textDecoration: 'none'
                            }
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.green.main, fontSize: '1rem', position: 'relative' }}
                          >
                            ✦
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ pl: theme.spacing(2), flex: 1 }}>
                        <TextField
                          key={meta.id}
                          label={
                            <Tooltip
                              title={<Typography variant="body2">{`Prompt: ${meta.prompt}`}</Typography>}
                              componentsProps={tooltipProps}
                            >
                              <Typography fontSize={21}>{meta.label}</Typography>
                            </Tooltip>
                          }
                          type="number"
                          name="content"
                          value={state.flexibleMetaschema?.[meta.label] ?? ''}
                          onChange={(event) => {
                            const { value } = event.target;
                            const numericValue = Number(value);
                            setState((prevState) => {
                              if (!prevState) return undefined;
                              const updatedFlexibleMetaschema = {
                                ...prevState.flexibleMetaschema,
                                [meta.label]: numericValue
                              };
                              return { ...prevState, flexibleMetaschema: updatedFlexibleMetaschema };
                            });
                          }}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter number here..."
                          InputProps={{
                            style: {
                              backgroundColor: 'transparent',
                              paddingLeft: 0,
                              paddingRight: 0,
                              borderBottom: `1px solid ${theme.palette.text.primary}`
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              transition: 'none',
                              backgroundColor: 'transparent',
                              color: theme.palette.secondary.light,
                              transform: 'translate(0, -6px) scale(0.75)',
                              ...theme.typography.h6
                            }
                          }}
                          sx={{
                            mb: theme.spacing(1),
                            mt: theme.spacing(1),
                            color: theme.palette.text.primary,
                            '& .MuiInputBase-inputMultiline': {
                              color: theme.palette.text.primary,
                              borderRadius: 0,
                              resize: 'vertical'
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none'
                              },
                              '&:hover fieldset': {
                                border: 'none'
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none'
                              },
                              borderRadius: 0,
                              color: theme.palette.text.primary,
                              backgroundColor: 'transparent'
                            },
                            '& .MuiInputLabel-root': {
                              borderRadius: 0,
                              color: theme.palette.text.primary,
                              '&.Mui-focused': {
                                borderRadius: 0,
                                color: theme.palette.text.primary
                              }
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              borderRadius: 0,
                              color: theme.palette.text.primary
                            },
                            '& .MuiInputBase-input::placeholder': {
                              borderRadius: 0,
                              color: theme.palette.text.primary,
                              opacity: 1
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>
          )}
        </Box>
      </Box>
      <SaveSuccessSnackbar
        autohideDuration={SNACKBAR_HANG_TIME.SLOW}
        saveSuccess={autofillSuccess}
        setSaveSuccess={setAutofillSuccess}
      />
      <SaveSuccessSnackbar saveSuccess={saveSuccess} setSaveSuccess={setSaveSuccess} />
    </EditPage>
  );
};
