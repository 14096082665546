export enum FeatureToggleFlags {
  /**
   * dictates whether or not a user is allowed to chat with OpenAI
   */
  CanChatWithOpenAI = 'canChatWithOpenAI',
  /**
   * whether or not the user can wire up client-side models
   */
  CanUseLocalModels = 'canUseLocalModels',
  /**
   * whether or not the user can invoke the flexible metaschema functionality
   */
  CanFillMetaschema = 'canFillMetaschema'
}

export type FeatureToggleFlagSettings = {
  [key in FeatureToggleFlags]?: boolean;
};
