import { Select, SelectOption } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import { Box, useTheme } from '@mui/material';

export const ModelSelection = ({
  boxStyle,
  selectedModel,
  onModelSelect
}: {
  boxStyle: object;
  selectedModel: string;
  onModelSelect: (model: string) => void;
}) => {
  const theme = useTheme();
  const [modelsList] = useGetApi<{ models: Array<{ code: string; name: string; isDefault: boolean }> }>(`model`);
  const modelsConfig = modelsList?.models?.map((model) => ({
    value: model.code,
    label: model.name,
    isLocal: false
  }));

  const handleModelChange = (option: SelectOption) => {
    const chosenModelId = option.value as string;
    onModelSelect(chosenModelId);
  };

  return (
    <Box sx={boxStyle}>
      <Select
        showNoneOption
        colorProp={theme.palette.green}
        id="model-selector"
        value={selectedModel || ''}
        onChange={(e) => handleModelChange(e)}
        label="Select Model"
        options={modelsConfig ?? []}
      />
    </Box>
  );
};
