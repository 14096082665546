import { User } from '@/types/user';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
  darken
} from '@mui/material';
import { memo, useEffect, useState } from 'react';

interface ProvisionNewUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (user: User) => void;
  user: User | null;
  theme: Theme;
}

export const EditProvisionedUserDialog: React.FC<ProvisionNewUserDialogProps> = memo(
  ({ open, onClose, user, onSave, theme }) => {
    const [editedUser, setEditedUser] = useState(user);

    useEffect(() => {
      setEditedUser(user);
    }, [user]);

    const handleDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setEditedUser((prev) => ({
        ...prev,
        toggles: { ...prev?.toggles, [name]: value },
        email: prev?.email || '',
        firstName: prev?.firstName || '',
        lastName: prev?.lastName || '',
        uuid: prev?.uuid || ''
      }));
    };

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setEditedUser((prev) => ({
        ...prev,
        toggles: { ...(prev?.toggles ?? {}), [name]: checked },
        email: prev?.email || '',
        firstName: prev?.firstName || '',
        lastName: prev?.lastName || '',
        uuid: prev?.uuid || ''
      }));
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Provision User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            disabled
            variant="standard"
            name="email"
            value={editedUser?.email || ''}
            sx={{
              '& .Mui-disabled': {
                color: darken(theme.palette.primary.contrastText, 0.3),
                '-webkit-text-fill-color': darken(theme.palette.primary.contrastText, 0.3)
              }
            }}
          />
          <Typography sx={{ pt: 2, color: theme.palette.grey[500] }} variant="caption" display="block" gutterBottom>
            FLAGS
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canChatWithOpenAI'] || false}
                onChange={handleToggleChange}
                name="canChatWithOpenAI"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="OpenAI Chat Access"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canFillMetaschema'] || false}
                onChange={handleToggleChange}
                name="canFillMetaschema"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="Can Fill Metaschema"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canAccessKnowledgeFinder'] || false}
                onChange={handleToggleChange}
                name="canAccessKnowledgeFinder"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="KF Access"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canUseLocalModels'] || false}
                onChange={handleToggleChange}
                name="canUseLocalModels"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="Local Model Chat Access"
          />
          <Typography sx={{ pt: 2, color: theme.palette.grey[500] }} variant="caption" display="block" gutterBottom>
            EXPERIENCES
          </Typography>
          <TextField
            margin="dense"
            label="Preferred Landing"
            type="text"
            fullWidth
            variant="standard"
            name="preferredLanding"
            value={editedUser?.toggles?.['preferredLanding'] || ''}
            onChange={handleDetailChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="destructive-light" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="clean-creative" onClick={() => onSave(editedUser as User)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
