import { SaveSuccessSnackbar } from '@/components';
import { AgentToInteractWith, ChatGPTMessage, ErrorMessage } from '@/features/chatbot';
import { FeatureToggleFlags, useFeatureToggle } from '@/features/feature-toggle';
import { useAgentChat } from '@/hooks/use-agent-chat';
import { useGetApi } from '@/hooks/use-get-api';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import { LibraryProject } from '@/types/projects';
import * as webllm from '@mlc-ai/web-llm';
import { Clear, SaveAlt, Send, ThumbDownSharp, ThumbUpSharp } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defaultModel } from '../../../../models/config';
import { DocumentCollection, FileMetadata } from '../../types';
import { ChatRoles, DOCUMENT_SEARCH_ERROR, TOOL_USE_REGEX } from '../../types/constants';
import { ModelSelection } from '../model-selection';
import { downloadConversation } from './chat-conversation-download';
import { InputField } from './chat-input';
import { MessageItem } from './chat-message-item';

/**
 * KF chat for given context
 * - handles chat state & actions
 * - disables chat UI & provides custom message if chat is disabled for a user
 */
export const ChatWithContext = ({
  searchResults,
  query,
  library,
  collection,
  document,
  documents
}: {
  documents: FileMetadata[];
  searchResults: FileMetadata[];
  library: LibraryProject;
  query?: string;
  collection?: DocumentCollection;
  document?: Partial<FileMetadata> | null;
}) => {
  const { flags } = useFeatureToggle();

  // message to show when chat is not available
  const noChatMessage: ChatGPTMessage = {
    role: ChatRoles.ASSISTANT,
    content: `Hi there! Unfortunately, AI-powered chat is not yet available for this library.`
  };

  const [modelsList] = useGetApi<{ models: Array<{ code: string; name: string; isDefault: boolean }> }>(`model`);
  const modelsConfig = modelsList?.models?.map((model) => ({
    value: model.code,
    label: model.name,
    isLocal: false
  }));
  const defaultModelCode = modelsList?.models?.find((model) => model.isDefault)?.code ?? defaultModel;
  // #region state
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };
  const [selectedDocumentSnippets, setSelectedDocumentSnippets] = useState<
    { content: string; pageNumber: number }[] | undefined
  >();
  const [feedbackThanks, setFeedbackThanks] = useState('');
  // #endregion state

  // #region client-side model selection
  const [localModelEngine, setLocalModelEngine] = useState<webllm.EngineInterface>();
  const [localModelInitState, setLocalModelInitState] = useState<string>();
  const [selectedModel, setSelectedModel] = useState<string>(defaultModelCode);
  const handleModelChange = (model: string) => {
    setSelectedModel(model);
  };
  const initProgressCallback = (report: webllm.InitProgressReport) => {
    setLocalModelInitState(report.text);
  };
  useEffect(() => {
    const loadLocalModel = async () => {
      if (selectedModel && !!modelsConfig?.find((model) => model.value === selectedModel)?.isLocal) {
        const engine = await webllm.CreateWebWorkerEngine(
          new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' }),
          selectedModel,
          { initProgressCallback }
        );
        setLocalModelEngine(engine);
      } else {
        setLocalModelEngine(undefined);
      }
    };
    loadLocalModel();
  }, [modelsConfig, selectedModel]);
  // #endregion client-side model selection

  // #region API handlers
  // GET
  const { getData: getAllSnippetsForDocument } = useGetApiViaCallback<{ content: string; pageNumber: number }[]>(
    `knowledge-finder/document/${library.uuid}/${collection?.uuid}/embeddings/${document?.uuid}`
  );
  // POST
  const { postData: searchOneDocument } = usePostApi<
    {
      content: string;
      pageNumber: number;
      similarity: number;
    }[]
  >(`knowledge-finder/document/search`);
  const { postData: postImpression } = usePostApi<void>(`knowledge-finder/conversation/impression`);
  const { postData: postConvertConversationToDownloadableFormat } = usePostApi<(string | unknown)[]>(
    `knowledge-finder/conversation/download`
  );
  // #endregion API handlers

  // #region initial welcome message
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const generateWelcomeMessage = useCallback(() => {
    if (firstMessageSent) return undefined;

    // Start with a general greeting.
    const opener = 'Hi there! If you have any questions or thoughts';
    const chatAndHelpMessage = "I'm here to chat and help.";
    let message = `Hi there! ${chatAndHelpMessage}`;

    // Customize the message based on the available context.
    if (document) {
      message = `${opener} about the document <strong>${document.title}</strong> in the <strong>${collection?.name}</strong> collection of the <strong>${library?.name}</strong> library, ${chatAndHelpMessage}`;
    } else if (query) {
      const inCollection = collection ? ` in the <strong>${collection.name}</strong> collection` : '';
      message = `${opener} about your search <strong>${query}</strong>${inCollection} of the <strong>${library?.name}</strong> library, ${chatAndHelpMessage}`;
    } else if (collection) {
      message = `${opener} about the <strong>${collection.name}</strong> collection in the <strong>${library?.name}</strong> library, ${chatAndHelpMessage}`;
    } else if (library) {
      message = `${opener} about the <strong>${library.name}</strong> library, ${chatAndHelpMessage}`;
    }
    message += `<br><br><strong>Remember:</strong> This chat is powered by an LLM. LLM outputs can contain errors. It is your responsibility to fact-check and verify the output.`;
    return message;
  }, [library, collection, document, query, firstMessageSent]);

  // #endregion initial welcome message

  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(generateWelcomeMessage());
  useEffect(() => {
    setWelcomeMessage(generateWelcomeMessage());
  }, [library, collection, document, query, generateWelcomeMessage]);
  // #endregion initial welcome message

  // #region chat ui state
  const { loading, messages, setMessages, error, sendMessage } = useAgentChat({
    projectId: library.uuid,
    welcomeMessage,
    stream: true,
    localModelEngine: localModelEngine
  });

  useEffect(() => {
    if (document?.autofillCompleted && document.flexibleMetaschema) {
      let autofillDetails = '';
      for (const autofillNode of Object.keys(document.flexibleMetaschema).filter(
        (key) => !key.includes('prism-reasoning')
      )) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(document.flexibleMetaschema as Record<string, any>)['prism-reasoning']?.[autofillNode]) {
          continue;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        autofillDetails += `<ul><strong>${autofillNode}</strong><ul><li><em>value</em>: ${(document.flexibleMetaschema as Record<string, any>)[autofillNode]}</li><li><em>reasoning</em>: ${(document.flexibleMetaschema as Record<string, any>)['prism-reasoning']?.[autofillNode]}</li></ul></ul>`;
      }

      setMessages((prev) => [
        ...prev,
        {
          role: ChatRoles.ASSISTANT,
          content: `I was able to autofill the following details based on the information within the document: ${autofillDetails}`
        }
      ]);
    }
  }, [document, setMessages]);

  useEffect(scrollToBottom, [messages]);
  // #endregion chat ui state

  // #region document snippets
  useEffect(() => {
    if (document && collection) {
      const fetchDocumentEmbeddings = async () => {
        const snippets = await getAllSnippetsForDocument();
        setSelectedDocumentSnippets(snippets);
      };
      // fetch document info from the documents or search results
      const documentInfo =
        documents?.find((doc) => doc.uuid === document.uuid) ??
        searchResults?.find((doc) => doc.uuid === document.uuid) ??
        document;

      if (documentInfo) {
        fetchDocumentEmbeddings();
      }
    } else {
      setSelectedDocumentSnippets(undefined);
    }
  }, [documents, getAllSnippetsForDocument, document, searchResults, collection]);

  // #endregion document snippets

  // #region chat actions
  const handleSendMessage = useCallback(
    async (message: string, role?: string, overrideExtras?: object) => {
      /**
       * the params sent are what opens up the ability to have a more context-aware chat.
       * the backend must accept the new parent parameter but will automatically accept
       * anything nested within the nodes as we pass them as stringified JSON.
       */
      const params = {
        ...(selectedModel && { model: selectedModel }),
        agent: localModelEngine
          ? AgentToInteractWith.KnowledgeFinderGetPrompt
          : AgentToInteractWith.KnowledgeFinderChat,
        message,
        query,
        role,
        results:
          searchResults?.length > 0
            ? JSON.stringify(
                searchResults.map((document) => {
                  return {
                    text: document.content ?? '',
                    doc: document.title ?? '',
                    page: document.pageNumber ?? ''
                  };
                })
              )
            : '', // just send all the results, let the backend trim etc
        extras: {
          library: library
            ? JSON.stringify({
                uuid: library.uuid,
                name: library.name,
                ...(library.description && { description: library.description })
              })
            : '',
          collection: collection
            ? JSON.stringify({
                uuid: collection.uuid,
                name: collection.name,
                ...(collection.description && { description: collection.description })
              })
            : '',
          documentsInView:
            documents?.length > 0
              ? JSON.stringify(
                  documents?.map((doc) => {
                    const docWithMetadata = {
                      title: doc.title,
                      displayName: doc.displayName,
                      content: doc.content?.substring(0, 1000),
                      ...(collection?.flexibleMetaschema && { ...doc?.flexibleMetaschema })
                    };
                    return docWithMetadata;
                  })
                )
              : '',
          document: document
            ? JSON.stringify({
                uuid: document.uuid,
                title: document.title,
                ...(document.content && { content: document.content }),
                ...(document.displayName && { displayName: document.displayName })
              })
            : '',
          snippets: document && selectedDocumentSnippets ? JSON.stringify(selectedDocumentSnippets) : '',
          singleDocumentMode: !!document,
          ...overrideExtras
        }
      };

      if (message.trim() === '' || loading) return;

      // Ignore empty messages or if the state isn't resolved fully
      if (!loading) {
        setInput('');
        await sendMessage(params);
        setFirstMessageSent(true);
        // Refocus the input after sending
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    },
    [
      localModelEngine,
      selectedModel,
      query,
      searchResults,
      library,
      collection,
      documents,
      document,
      selectedDocumentSnippets,
      loading,
      sendMessage
    ]
  );

  const trackImpression = useCallback(
    async (isGood: boolean) => {
      await postImpression({
        libraryId: library.uuid,
        ...(collection && { collectionId: collection.uuid }),
        ...(document && { documentId: document.uuid }),
        isGood
      });
      setFeedbackThanks('Thank you for your feedback!');
    },
    [postImpression, library.uuid, collection, document]
  );
  // #endregion chat actions

  // #region tool execution
  const search_doc = useCallback(
    async (args: { query: string }) => {
      const { query } = args;

      try {
        const results = await searchOneDocument({
          query,
          documentId: document?.documentId ?? document?.uuid,
          projectId: library.uuid,
          documentCollectionId: collection!.uuid
        });
        handleSendMessage(`I found ${results.length} results for: ${query}`, 'system', {
          snippets: JSON.stringify(results)
        });
        return `Successfully searched for: ${query}`;
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: ChatRoles.ASSISTANT,
            content: DOCUMENT_SEARCH_ERROR
          } as ChatGPTMessage
        ]);
        return `Error searching ${document?.title} for: ${query}`;
      }
    },
    [collection, document, handleSendMessage, library, searchOneDocument, setMessages]
  );

  useEffect(() => {
    const toolRegistry = {
      search_doc
    };

    const executeTool = async (toolName: keyof typeof toolRegistry, args: unknown) => {
      const toolFunction = toolRegistry[toolName];
      if (toolFunction) {
        try {
          const result = await toolFunction(args as never);
          console.log(`Execution result: ${result}`);
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: 'system', content: `Tool ${toolName} used successfully` }
          ]);

          return result;
        } catch (error) {
          console.error(`Error executing tool ${toolName}:`, error);
        }
      } else {
        console.warn(`No tool registered with the name: ${toolName}`);
      }
    };

    const lastMessage = messages[messages.length - 1]?.content;
    // Use a regex to match the pattern [TOOL-USE]<tool ...>...</tool>;
    const match = lastMessage?.match(TOOL_USE_REGEX);

    if (match) {
      const toolName = match[1].trim();
      const toolDataString = match[2];

      try {
        // Assuming the tool data is a JSON string
        const toolDataJSON = JSON.parse(toolDataString);
        console.log('Parsed tool name:', toolName);
        console.log('Parsed tool data:', toolDataJSON);
        executeTool(toolName as keyof typeof toolRegistry, toolDataJSON);
      } catch (error) {
        console.error('Error parsing tool data:', error);
      }
    }
  }, [handleSendMessage, messages, search_doc, setMessages]);
  // #endregion tool execution

  // #region date
  const currentDate = new Date();
  const date = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(currentDate);
  // #endregion date

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 100px)' }}>
      {localModelInitState && !localModelInitState?.startsWith('Finish loading') && (
        <Box
          sx={{
            position: 'absolute',
            p: theme.spacing(3),
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000
          }}
          onMouseEnter={(e) => e.stopPropagation()}
        >
          <CircularProgress sx={{ color: theme.palette.green.dark }} />
          <Typography variant="subtitle1" sx={{ pt: theme.spacing(3), color: theme.palette.secondary.dark }}>
            {localModelInitState}
          </Typography>
        </Box>
      )}
      <Typography
        variant="subtitle2"
        sx={{
          pt: theme.spacing(1),
          pb: theme.spacing(1),
          pl: theme.spacing(2),
          color: theme.palette.grey[400]
        }}
      >
        {date}
      </Typography>
      {flags?.canUseLocalModels && (
        <ModelSelection
          boxStyle={{ p: theme.spacing(2) }}
          selectedModel={selectedModel}
          onModelSelect={handleModelChange}
        />
      )}

      {/* Messages container */}
      <Box
        sx={{
          flexGrow: 1, // Makes this box take up all available space.
          overflowY: 'auto',
          marginBottom: theme.spacing(1), // allow the scroll to get all the way to the bottom each time
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          msOverflowStyle: 'none'
        }}
      >
        <List>
          {flags?.[FeatureToggleFlags.CanChatWithOpenAI] ? (
            messages.map((msg, index) => <MessageItem key={index} msg={msg} index={index} theme={theme} />)
          ) : (
            <MessageItem msg={noChatMessage} index={0} theme={theme} />
          )}
        </List>
        <ErrorMessage error={error} />
        <div ref={messagesEndRef} />
      </Box>

      {/* Chat input container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
          position: 'sticky', // stick to the bottom of the chat
          bottom: 0,
          zIndex: 10, // always overlay above any text flowing in
          padding: isSmallScreen ? theme.spacing(2) : theme.spacing(0),
          paddingTop: isSmallScreen ? theme.spacing(1) : theme.spacing(0)
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.lighter,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            borderTopRightRadius: theme.sharperBorderRadius,
            borderBottomRightRadius: theme.sharperBorderRadius,
            borderTopLeftRadius: theme.sharperBorderRadius,
            borderBottomLeftRadius: theme.sharperBorderRadius,
            m: 1,
            minHeight: theme.spacing(6) // min space for send button when only button
          }}
        >
          <InputField
            inputRef={inputRef}
            input={input}
            setInput={setInput}
            handleSendMessage={handleSendMessage}
            disabled={loading || !flags?.[FeatureToggleFlags.CanChatWithOpenAI]}
            theme={theme}
          />
          <IconButton
            disableRipple
            disabled={loading || !flags?.[FeatureToggleFlags.CanChatWithOpenAI]}
            sx={{
              color: theme.palette.primary.main,
              paddingRight: theme.spacing(2),
              pb: theme.spacing(3),
              position: 'absolute', // keep send button in bottom right if input gets long
              bottom: 0,
              right: 0
            }}
            aria-label="send message"
            onClick={() => handleSendMessage(input)}
          >
            <Send />
          </IconButton>
        </Box>
      </Box>

      {/* Chat settings button */}
      <Box
        sx={{
          position: 'absolute',
          bottom: theme.spacing(1),
          right: theme.spacing(1),
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {messages.length > 1 && (
          <>
            <Tooltip title={<Typography variant="body2">Download Conversation</Typography>}>
              <IconButton
                size="small"
                sx={{ mr: theme.spacing(3) }}
                onClick={async () => {
                  await downloadConversation({
                    messages,
                    apiHandler: postConvertConversationToDownloadableFormat,
                    libraryId: library.uuid,
                    ...(collection && { collectionId: collection.uuid }),
                    ...(document && { documentId: document.uuid })
                  });
                }}
              >
                <SaveAlt />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="body2">Helpful Conversation</Typography>}>
              <IconButton
                size="small"
                sx={{ mr: theme.spacing(3) }}
                onClick={() => {
                  trackImpression(true);
                }}
              >
                <ThumbUpSharp />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="body2">Conversation Needs Improvement</Typography>}>
              <IconButton
                size="small"
                sx={{ mr: theme.spacing(3) }}
                onClick={() => {
                  trackImpression(false);
                }}
              >
                <ThumbDownSharp />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="body2">Reset Conversation</Typography>}>
              <IconButton
                size="small"
                sx={{ mr: theme.spacing(3) }}
                onClick={() => {
                  setFirstMessageSent(false);
                  setMessages((prev) => [prev[0]]);
                }}
              >
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Typography variant="button">Chat</Typography>
      </Box>
      <SaveSuccessSnackbar
        paddingRight={0}
        anchorOriginVertical="bottom"
        saveSuccess={feedbackThanks}
        setSaveSuccess={setFeedbackThanks}
      />
    </Box>
  );
};
